<template>
  <Modal
    click-to-close
    z-index="21"
    title-text="Адрес доставки"
    hide-footer
    classes="modal fixed-bottom-modal"
    @close="$emit('close')"
    @update:model-value="$emit('update:modelValue', false)"
  >
    <form class="mm-clients-approval">
      <TextField
        v-model="form.clientName.value.value"
        :validation-field="form.clientName"
        :max-length="LONG_TEXT"
        label="ФИО"
        icon-disabled
        is-only-submit-validate
      />

      <TextField
        v-model="form.companyName.value.value"
        :validation-field="form.companyName"
        :max-length="LONG_TEXT"
        label="Название компании"
        icon-disabled
        is-only-submit-validate
      />

      <TextField
        v-model="form.email.value.value"
        :validation-field="form.email"
        label="Электронная почта"
        icon-disabled
        is-only-submit-validate
      />

      <TextField
        v-model="form.phone.value.value"
        :validation-field="form.phone"
        :mask="EInputMasks.PhoneSpaced"
        label="Телефон"
        icon-disabled
        is-only-submit-validate
        @keydown="TelephoneUtil.onKeydownPhone(
          form.phone.value.value,
          $event,
          (val) => form.phone.value.value = val,
        )"
      />

      <Recaptcha
        ref="LazyRecaptchaRef"
        size="invisible"
        badge="inline"
        @on-verify="onVerifyToken"
        @on-expired="onExpiredCaptcha"
      />

      <button
        type="button"
        class="btn btn-primary"
        @click="onSubmit"
      >
        Отправить заявку
      </button>

      <p>
        Отправляя заявку, вы соглашаетесь с
        <a
          :href="ECommonFile.PrivacyPolicy"
          target="_blank"
        >
          политикой обработки <br>персональных данных
        </a>
      </p>
    </form>
  </Modal>
</template>

<script lang="ts" setup>
import Modal from 'shared/components/modals/Modal.vue';
import TextField from 'shared/components/TextField.vue';
import { IClientApproval, IClientApprovalWithToken } from 'models/clientApproval.model';
import { Validators } from 'shared/utils/validators.util';
import { useField, useForm } from 'vee-validate';
import { LONG_TEXT } from 'shared/constants/inputLengths.const';
import { EInputMasks } from 'shared/enums/inputMasks.enum';
import { EGrecaptchaEnabledFlags } from 'shared/enums/grecaptchaEnabledFlags.enum';
import Recaptcha from 'shared/components/Recaptcha.vue';
import { ECommonFile } from 'shared/enums/commonFile.enum';
import { TelephoneUtil } from 'shared/utils/telephone.util';

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean);
  (e: 'close');
  (e: 'ok', user: IClientApprovalWithToken);
}>();

enum EFormFields {
  ClientName = 'clientName',
  CompanyName = 'companyName',
  Email = 'email',
  Phone = 'phone',
}

const validationSchema = {
  [EFormFields.ClientName]: [Validators.required(), Validators.maxLength(1024)],
  [EFormFields.CompanyName]: [Validators.required(), Validators.maxLength(1024)],
  [EFormFields.Phone]: [Validators.required(), Validators.phoneNumber()],
  [EFormFields.Email]: [Validators.required(), Validators.yupEmail()],
};

const { validate, meta, setTouched } = useForm<IClientApproval>({
  validationSchema,
});

const form = reactive({
  clientName: useField<string>(EFormFields.ClientName),
  companyName: useField<string>(EFormFields.CompanyName),
  phone: useField<string>(EFormFields.Phone, undefined, { initialValue: '' }),
  email: useField<string>(EFormFields.Email, undefined, { initialValue: '' }),
});

const LazyRecaptchaRef = ref<InstanceType<typeof Recaptcha> | null>(null);
const isRecaptchaEnabled = computed(() => useRuntimeConfig().public?.grecaptchaEnabled === EGrecaptchaEnabledFlags.Enabled); // eslint-disable-line

function onVerifyToken(token) {
  emits('ok', {
    clientName: form.clientName.value.value ?? '',
    companyName: form.companyName.value.value ?? '',
    phone: form.phone.value.value,
    email: form.email.value.value,
    token,
  });
}

function onExpiredCaptcha(): void {
  LazyRecaptchaRef?.value.reset();
}

async function onSubmit(): Promise<void> {
  try {
    await validate();

    setFormTouched();
    if (!meta.value?.valid) {
      return;
    }

    if (!isRecaptchaEnabled.value) {
      onVerifyToken('');
    }

    LazyRecaptchaRef?.value.execute();
  }
  catch (err) {
    console.error(err);
  }
}

function setFormTouched(): void {
  setTouched({
    [EFormFields.ClientName]: true,
    [EFormFields.CompanyName]: true,
    [EFormFields.Phone]: true,
    [EFormFields.Email]: true,
  });
}
</script>

<style lang="scss" scoped>
@import 'shared/assets/styles/base/common/variables';

.mm-clients-approval {
  p {
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
    color: $text-black;
    margin-bottom: 0;

    a {
      color: $link;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .mm-input {
    padding-bottom: 12px;
  }

  .btn {
    margin-top: 20px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    width: 100%;

    &.btn-secondary {
      pointer-events: none;
    }
  }
}

@media only screen and (max-width: 767px) {
    :deep(.modal.fixed-bottom-modal) {
      .modal-content {
        box-shadow: unset;
      }
    }
}

@media only screen and (max-width: 460px) {
  .mm-clients-approval p a {
    br {
      display: none;
    }
  }
}
</style>
